import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { Link } from "gatsby"
import SEO from "components/seo"

import { lightTheme } from "styles/theme"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import GlobalStyle from "styles/globalStyle"
import Signup from "components/signup"
import GlobalStateProvider from "context/provider"


const Logo = "https://hrflow-ai.imgix.net/logo-hrflow.svg"
const labelImg = "https://hrflow-ai.imgix.net/label.svg"
const pwc = "https://hrflow-ai.imgix.net/pwc.svg"
const sanofi = "https://hrflow-ai.imgix.net/sanofi.svg"
const gojob = "https://hrflow-ai.imgix.net/gojob.svg"

const StyledLayout = styled.div`
`
const StyledBar = styled.div`
  max-width: 90rem;
  margin: auto;
  padding: 1rem;
`
const StyledLogoWrapper = styled(Link)`
  img {
    width: 8rem;
  }
`

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  max-width: 400px;
  margin: auto;
  margin-top: 3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;
    max-width: 960px;
  }
`

const StyledFeatures = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #0a2540;
  color: white;
  height: fit-content;
  h1 {
    line-height: normal;
    font-size: 2rem;
    text-align: center;
    color: white;
    font-weight: 600;
    margin: 1rem 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      padding: 0.5rem;
      margin: 0.5rem 0;
      .label {
        background-image: url(${labelImg});
        width: 4rem;
        height: 1.8rem;
        background-size: 2.8rem 1.8rem;
        background-repeat: no-repeat;
        background-position: center;
        line-height: 1.5rem;
        font-weight: 600;
        font-size: .8rem;
        letter-spacing: -.05rem;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: .3rem;
      }
      .intro {
        color: white;
        line-height: 1.85rem;
        font-size: 0.9rem;
        letter-spacing: -.05rem;
        text-align: left;
      }
    }
  }
  h3 {
    line-height: 1.85rem;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: -.05rem;
    color: white;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 1rem;
      width: 3rem;
    }
  }
`

const StyledSignin  = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #999;
  box-sizing: border-box;
  text-align: center;
  a {
    color: #999 !important;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 5px;
  }
`

const StyledFormWrapper = styled.div`

`
const SignupPage = () => {

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: false,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }
  const theme = lightTheme
  return (
    <GlobalStateProvider initialState={globalState}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <StyledLayout>
          <SEO
            frontmatter={{
              title: "Sign up | HrFlow.ai",
              url: "https://hrflow.ai/signup/",
              description: "HrFlow.ai is a human resources data platform (HRDP) that helps you extract, enrich, qualify and control your hr data.",
              keywords: "HR Data, HR AI, HR Analytics, HR Integrations, HR API, HR Workflows"
            }}
          />
          <StyledBar>
            <StyledLogoWrapper to="/">
              <img src={Logo} alt="Hrflow.ai logo"/>
            </StyledLogoWrapper>
          </StyledBar>
          <StyledWrapper>
            <StyledFeatures>
              <h1>Sign up</h1>
              <ul>
                <li>
                  <div className="label">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="intro">Access to products like Parsing, Tagging, and more</div>
                </li>
                <li>
                  <div className="label">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="intro">Install in less than 5 minutes</div>
                </li>
                <li>
                  <div className="label">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="intro">Setup integrations in 1-click</div>
                </li>
                <li>
                  <div className="label">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="intro">Implement low-code workflows</div>
                </li>
                <li>
                  <div className="label">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="intro">Supports Python, PHP, JavaScript and more</div>
                </li>
              </ul>
              <h3>Powering +1,000 software and organizations</h3>
              <div className="logos">
                <img src={pwc} alt="pwc logo" />
                <img src={sanofi} alt="sanofi logo" />
                <img src={gojob} alt="gojob logo" />
              </div>
            </StyledFeatures>
            <StyledFormWrapper>
              <Signup />
              <StyledSignin>
                  Already have an account? <br />
                  <Link to="/signin/">
                    signin here
                  </Link>
              </StyledSignin>
            </StyledFormWrapper>
          </StyledWrapper>
        </StyledLayout>
        <img src="https://ws.zoominfo.com/pixel/62e2f6695d30f0008e3f7f63" width="1" height="1" style={{display: 'none'}} alt="websights"/>
      </ThemeProvider>
    </GlobalStateProvider>
  )
}

export default SignupPage